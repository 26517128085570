import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css"; // for snow theme
import { toolbarOptionsBlog } from "@/plugins/quill/toolbarOptionsBlog";
import Quill from "quill";

import quillCounter from "@/plugins/quill/quillCounter.js";

import ImageResize from "quill-image-resize";
Quill.register("modules/quillCounter", quillCounter);

Quill.register("modules/imageResize", ImageResize);

var icons = Quill.import("ui/icons");
    icons["undo"] = `<svg viewbox="0 0 18 18">
    <polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
    <path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
  </svg>`;
    icons["redo"] = `<svg viewbox="0 0 18 18">
    <polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
    <path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
  </svg>`;
const quillOptionsBlog = {
formats: [
  "undo",
  "redo",
  "bold",
  "italic",
  "underline",
  "strike",
  "link",
  "color",
  "background",
  "list",
  "indent",
  "blockquote",
  "align",
  "image",
  "width",
  "clean",
],
modules: {
  quillCounter: {
    container: "#counterQuill",
    unit: "(знаков)",
    max: 50000,
    min: 0,
  },
  imageResize: {
    displayStyles: {
      backgroundColor: "black",
      border: "none",
      color: "white",
    },
    modules: ["Resize", "DisplaySize", "Toolbar"],
  },
  history: {
    delay: 2000,
    maxStack: 500,
    userOnly: true,
  },
  toolbar: {
    container: toolbarOptionsBlog,
     handlers: {
  redo() {
    this.quill.history.redo();
  },
  undo() {
    this.quill.history.undo();
  }}
  },
},
};
export { quillOptionsBlog };
