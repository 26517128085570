<template>
  <div>
    <v-toolbar tile flat elevation="1">
      <v-btn :to="{ name: 'CabinetBlogs' }" class="no-active" icon>
        <v-icon>$vuetify.icons.arrow_left</v-icon>
      </v-btn>
      <v-toolbar-title v-if="blogId">{{
        $vuetify.breakpoint.smAndUp ? "Редактировать блог" : "Редактировать"
      }}</v-toolbar-title>
      <v-toolbar-title v-else>{{
        $vuetify.breakpoint.smAndUp ? "Добавить блог" : "Добавить"
      }}</v-toolbar-title>

      <v-spacer></v-spacer>
      <div v-if="blog" :class="blog.status ? 'success--text' : 'primary--text'">
        Ваш блог {{ !blog.status ? "не " : "" }} опубликован
      </div>
      <v-spacer></v-spacer>

      <v-btn
        v-if="blogId && blog"
        :to="{ name: 'BlogShow', params: { slug: blog.slug } }"
        text
        tile
        >{{
          $vuetify.breakpoint.smAndUp ? "Просмотр блога" : "Просмотр"
        }}</v-btn
      >
    </v-toolbar>
    <v-container v-if="!loadPage" class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" md="12">
          <v-card class="pa-4" v-if="blogId && showMsg">
            <v-row class="text-center">
              <v-col cols="12"> Ваш блог успешно создан </v-col>
              <v-col cols="12">
                <v-btn
                  v-if="blog"
                  class="mr-2"
                  :to="{ name: 'BlogShow', params: { slug: blog.slug } }"
                  >{{
                    $vuetify.breakpoint.smAndUp ? "Просмотр блога" : "Просмотр"
                  }}</v-btn
                >
                <v-btn class="ml-2" @click="showMsg = 0">Редактировать</v-btn>
              </v-col>
            </v-row>
          </v-card>
          <div v-else class="elevation-0">
            <v-form v-model="valid" ref="form">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="title"
                    name="inputTitle"
                    label="Заголовок"
                    required
                    :rules="titleRules"
                  ></v-text-field>

                  <v-textarea
                    name="inputExcerpt"
                    label="Aннотация (не обязательно)"
                    value
                    v-model="excerpt"
                    :rules="excerptRules"
                    class="mb-4"
                  ></v-textarea>

                  <quill-editor
                    ref="myQuillEditor"
                    v-model="body"
                    :options="editorOption"
                  />
                  <div id="counterQuill"></div>

                  <v-checkbox
                    v-model="status"
                    label="Опубликовать"
                  ></v-checkbox>
                  <v-btn v-if="blogId" dark color="primary" @click="updateBlog"
                    >Обновить</v-btn
                  >
                  <v-btn v-else dark color="primary" @click="submit"
                    >Сохранить</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { quillEditor } from "vue-quill-editor";
import { quillOptionsBlog } from "@/plugins/quill/quillOptionsBlog";
// import "quill/dist/quill.core.css"; // import styles
// import "quill/dist/quill.snow.css"; // for snow theme
// import Quill from "quill";
// import quillCounter from "@/plugins/quill/quillCounter.js";

// import ImageResize from "quill-image-resize";
// Quill.register("modules/quillCounter", quillCounter);

// Quill.register("modules/imageResize", ImageResize);
// import { toolbarOptionsBlog } from "@/plugins/quill/toolbarOptionsBlog";
import { mapGetters } from "vuex";
export default {
  components: {
    quillEditor,
  },
  data: () => ({
    editorOption: quillOptionsBlog,
    // editorOption: {
    //   formats: [
    //     "bold",
    //     "italic",
    //     "underline",
    //     "strike",
    //     "color",
    //     "background",
    //     "list",
    //     "indent",
    //     "blockquote",
    //     "align",
    //     "image",
    //     "width",
    //     "clean",
    //     "link",
    //   ],
    //   modules: {
    //     quillCounter: {
    //       container: "#counterQuill",
    //       unit: "(знаков)",
    //       max: 10000,
    //       min: 10,
    //     },
    //     imageResize: {
    //       displayStyles: {
    //         backgroundColor: "black",
    //         border: "none",
    //         color: "white",
    //       },
    //       modules: ["Resize", "DisplaySize", "Toolbar"],
    //     },
    //     toolbar: {
    //       container: toolbarOptionsBlog,
    //     },
    //   },
    // },
    blogId: null, //Id серии которую будем редактировать
    showMsg: 0, //Сообщение что блог только что создан
    valid: true,
    title: "",
    body: "",
    excerpt: "", //Аннотация
    status: false,
    requiredRules: [(v) => !!v || "Поле обязательно"],
    titleRules: [
      (v) => !!v || "Поле обязательно",
      (v) => v.length >= 1 || "Минимум 1 символ",
    ],
    excerptRules: [
      // v => !!v || 'Поле обязательно',
      (v) =>
        (!!v === v.length >= 10) === v.length <= 500 ||
        "Минимум 10 символов, максимум 500 символов",
    ],
  }),
  methods: {
    validate() {
      let text_count = this.$refs.myQuillEditor.quill
        .getModule("quillCounter")
        .getCount();

      // this.$refs.myQuillEditor.quill
      // .getModule("quillCounter")
      // .validate();
      return (
        this.$refs.form.validate() &&
        text_count <= this.editorOption.modules.quillCounter.max &&
        text_count >= this.editorOption.modules.quillCounter.min
      );
    },
    submit() {
      if (!this.validate()) return;
      let text_count = this.$refs.myQuillEditor.quill
        .getModule("quillCounter")
        .getCount();
      this.loading = true;
      let app = this;
      this.$store
        .dispatch("cabinet_blogs/addBlog", {
          title: app.title,
          excerpt: app.excerpt,
          body: app.body,
          status: app.status,
          text_count: text_count,
        })
        .then((response) => {
          app.$router.push({
            name: "CabinetBlogEdit",
            params: { id: response.data.blog.id },
            query: { show_msg: 1 },
          });
        });
    },
    updateBlog() {
      if (!this.validate()) return;
      let text_count = this.$refs.myQuillEditor.quill
        .getModule("quillCounter")
        .getCount();
      this.loading = true;
      let app = this;
      this.$store.dispatch("cabinet_blogs/updateBlog", {
        blog_id: app.blogId,
        title: app.title,
        excerpt: app.excerpt,
        body: app.body,
        status: app.status,
        text_count: text_count,
      });
      // .then((response) => {
      // });
    },
    getBlog() {
      let app = this;
      this.$store.dispatch("cabinet_blogs/getEditBlog", {
        blog_id: app.blogId,
      });
    },
    getBlogCreate() {
      this.$store.commit("cabinet_blogs/SET_BLOG", null);
    },

    updateData() {
      // console.log('update_data')
      this.title = this.blog.title;
      this.excerpt = this.blog.excerpt;
      this.body = this.blog.body;
      this.status = +this.blog.status;
    },
  },

  watch: {
    blog() {
      if (this.blog) {
        this.updateData();
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      blog: "cabinet_blogs/blog",
    }),
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },
  mounted() {
    this.blogId = this.$route.params.id;
    if (this.blogId) {
      this.showMsg = this.$route.query.show_msg;
      this.getBlog();
    } else {
      this.getBlogCreate();
    }

    this.editorOption.modules.quillCounter.min = 10;
    this.editorOption.modules.quillCounter.max = 10000;
    //Если имеется id - редактирование, нету - добавление
  },
};
</script>
